<template>
  <div class="director-list">
    <div class="title">理事列表</div>
    <div class="list-title">
      <div class="rank"></div>
      <div class="director">理事</div>
      <div class="ticket">实时票数/占比</div>
      <div class="operation">操作</div>
    </div>
    <DirectorListItem
      v-for="item in 2"
      :key="item"
      @show="handleShowVote"
    ></DirectorListItem>
    <div class="line"></div>
    <VotePop v-if="voteShow" @close="handleShowVote"></VotePop>
  </div>
</template>

<script>
import VotePop from './VotePop.vue'
import DirectorListItem from './DirectorListItem.vue'
export default {
  name: '',
  data () {
    return {
      voteShow: false
    }
  },
  methods: {
    handleShowVote () {
      this.voteShow = !this.voteShow
    }
  },
  mounted () {
  },
  components: { DirectorListItem, VotePop }
}
</script>

<style scoped lang="scss">
.director-list {
  margin-bottom: 50px;
  margin-top: 15px;
  padding: 20px 30px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  .title {
    font-weight: bold;
    font-size: 17px;
  }
  .list-title {
    font-size: 12px;
    color: #2a2b33;
    padding: 15px 0;
    display: flex;
    flex-basis: 40px;
    justify-content: space-between;
    text-align: center;
    .rank {
      width: 20px;
    }
    .director {
      width: 100px;
    }
    .ticket {
      text-align: center;
      width: 100px;
    }
    .operation {
      width: 66px;
      text-align: right;
    }
  }
  .line {
    height: 1px;
    background-color: #e3e3e3;
  }
}
</style>
