<template>
  <VanPopup
    v-model="isShow"
    style="width: 93%; max-width: 400px"
    round
    @click-overlay="closePop"
  >
    <div class="wrap">
      <div class="title-wrap">
        <div class="title">投票MEB TEAM</div>
        <div class="close-icon" @click="closePop">✕</div>
      </div>
      <div class="content">
        <div class="des">
          1个xMEB代表1票，请输入1的整数倍；你可以随时赎回你的凭证，赎回投票凭证有三天解锁期；
        </div>
        <div class="stake-num">
          <div class="title">输入质押数量</div>
          <div class="value">余额：{{ mebBalance }} MEB</div>
        </div>
        <div class="meb-input">
          <input type="number" id="meb-input" v-model="mebValue" />
          <button @click="handleToMax">最大</button>
        </div>
        <div class="cofirm-button" @click="handleToConfirm">确定</div>
      </div>
    </div>
  </VanPopup>
</template>

<script>
import config from '@/config/app.config'
const contractsInfo = config.contractsInfo
export default {
  name: 'VotePop',
  data () {
    return {
      tokenMeb: contractsInfo.tokenGEPContract,
      mebBalance: 0,
      mebValue: 0,
      isShow: true
    }
  },
  methods: {
    closePop () {
      this.$emit('close')
    },
    async getMebBalance () {
      const mebResp = await this.$web3.balanceOf(this.tokenMeb)
      if (mebResp.success) {
        this.mebBalance = this.$gbUtils.formatTwoBalance(mebResp.result) || 0
      }
    },
    handleToMax () {
      this.mebValue = this.mebBalance
    },
    handleToConfirm () {
      this.closePop()
    }
  },
  mounted () {
    this.getMebBalance()
  }
}
</script>

<style scoped lang="scss">
.wrap {
  font-size: 12px;
  .title {
    font-weight: bold;
  }
  .line {
    height: 1px;
    background-color: #e3e3e3;
  }
  .title-wrap {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold;
    border-bottom: 1px solid #e3e3e3;
    .close-icon {
      background-color: #000 !important;
      font-size: 14px;
    }
  }
  .content {
    padding: 15px 25px;
    .des {
      padding: 15px 0;
      color: #818086;
      line-height: 1.2rem;
    }
    .stake-num {
      display: flex;
      justify-content: space-between;
    }
    .meb-input {
      box-sizing: border-box;

      border-radius: 10px;
      margin-top: 15px;
      background-color: #f0f0f0;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      #meb-input {
        height: 30px;
        background-color: transparent;
        border: none;
        width: 60%;
      }
      button {
        width: 66px;
        border: none;
        height: 30px;
        background: #000;
        color: #fff;
        text-align: center;
        opacity: 1;
        border-radius: 10px;
      }
    }
  }
  .cofirm-button {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: bold;
    height: 50px;
    line-height: 50px;
    background: #000;
    color: #fff;
    text-align: center;
    border-radius: 10px;
  }
}
</style>
