<template>
  <div>
    <div class="list-item" @click="handleToDetail">
      <div class="tag tag1" v-if="false">正式</div>
      <div class="tag tag2" v-else>候选</div>
      <div class="content">
        <div class="rank">1</div>
        <div class="director">MEB TEAM21321321</div>
        <div class="ticket">
          <div class="ticket">28,413,123</div>
          <div class="percent">24.12%</div>
        </div>
        <div class="operation">
          <div class="operation-button" @click.stop="handleShowVote">操作</div>
        </div>
      </div>
    </div>
    <div class="detail" v-show="detailIsShow">
      <div class="info-title">竞选人信息:</div>
      <div class="oneline">
        <div class="time">
          <div class="title">创建时间</div>
          <div class="value">2022-03-08 12:12:11</div>
        </div>
        <div class="stake-num">
          <div class="title">质押凭证数</div>
          <div class="value">213,123,431</div>
        </div>
      </div>
      <div class="twoline">
        <div class="time">
          <div class="title">创建时间</div>
          <div class="value">2022-03-08 12:12:11</div>
        </div>
      </div>
      <div class="twoline">
        <div class="time">
          <div class="title">竞选说明</div>
          <div class="value">2022-03-08 12:12:11</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  name: 'VotePop',
  data () {
    return {
      detailIsShow: false

    }
  },
  methods: {
    handleToDetail () {
      this.detailIsShow = !this.detailIsShow
    },
    handleShowVote () {
      this.$emit('show')
    }
  },
  mounted () {

  }
}
</script>

<style scoped lang="scss">
.list-item {
  border-top: 1px solid #e3e3e3;
  padding-bottom: 15px;
  .tag {
    width: 35px;
    height: 18px;
    color: #fff;
    border-radius: 0px 0px 5px 0px;
    font-size: 12px;
    text-align: center;
    line-height: 18px;
  }
  .tag1 {
    background: #000;
  }
  .tag2 {
    background-color: #a2a2a2;
  }
  .content {
    display: flex;
    flex-basis: 6rem;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    .rank {
      width: 20px;
    }
    .director {
      width: 100px;
      text-align: center;
    }
    .ticket {
      text-align: center;
      width: 100px;
    }
    .operation {
      width: 66px;
      height: 30px;
      background: #000;
      text-align: center;
      line-height: 30px;
      color: #fff;
      border-radius: 10px;
    }
  }
}
.detail {
  border-top: 1px solid #e3e3e3;
  font-size: 12px;
  box-sizing: border-box;
  padding: 15px;
  background-color: #f9f9f9;
  .oneline {
    display: flex;
    justify-content: space-between;
  }
  .twoline {
    margin-top: 10px;
  }
  .title {
    font-weight: bold;
  }
  .value {
    color: #818086;
  }
  .info-title {
    font-weight: bold;
    padding-bottom: 10px;
  }
}
</style>
