<template>
  <VanPopup
    v-model="isShow"
    style="width: 93%; max-width: 400px"
    round
    @click-overlay="closePop"
  >
    <div class="wrap">
      <div class="title-wrap">
        <div class="title">成为候选理事</div>
        <div class="close-icon" @click="closePop">✕</div>
      </div>
      <div class="content">
        <div class="stake-num">
          <div class="title">输入质押数量</div>
          <div class="value">余额：{{ mebBalance }} MEB</div>
        </div>
        <div class="meb-input">
          <input type="number" id="meb-input" v-model="mebValue" />
          <button @click="handleToMax">最大</button>
        </div>
        <div class="name-input">
          <div class="title">*理事名称</div>
          <input
            type="text"
            placeholder="您将以此名称来竞选MEB理事"
            v-model="directorName"
          />
        </div>
        <div class="name-input">
          <div class="title">*竞选说明（推荐使用英文）</div>
          <input type="text" placeholder="竞选说明理由" v-model="directorDes" />
        </div>
        <div class="des">
          <div>你至少需要质押2000000个MDAO凭证，才可成为MEB候选理事</div>
          <div>*如果你未被选中为理事，可以随时取回凭证，但是有三天解锁期</div>
          <div>
            *如果你被选中为理事，那么你将不能赎回质押的凭证直到正式理事期结束
          </div>
        </div>
        <div class="cofirm-button">确定</div>
      </div>
    </div>
  </VanPopup>
</template>

<script>
import config from '@/config/app.config'
const contractsInfo = config.contractsInfo

export default {
  name: 'DirectorPop',
  data () {
    return {
      isShow: true,
      tokenMeb: contractsInfo.tokenGEPContract,
      mebBalance: 0,
      mebValue: 0,
      directorName: '',
      directorDes: ''
    }
  },
  methods: {
    closePop () {
      this.$emit('close')
    },
    async getMebBalance () {
      const mebResp = await this.$web3.balanceOf(this.tokenMeb)
      if (mebResp.success) {
        this.mebBalance = this.$gbUtils.formatTwoBalance(mebResp.result) || 0
      }
    },
    handleToMax () {
      this.mebValue = this.mebBalance
    }
  },
  mounted () {
    this.getMebBalance()
  }
}
</script>

<style scoped lang="scss">
.wrap {
  font-size: 12px;
  .title {
    font-weight: bold;
  }
  .line {
    height: 1px;
    background-color: #e3e3e3;
  }
}
.title-wrap {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid #e3e3e3;
  .close-icon {
    background-color: #000 !important;
    font-size: 14px;
  }
}
.content {
  padding: 15px 25px;
  .stake-num {
    display: flex;
    justify-content: space-between;
  }
  .meb-input {
    box-sizing: border-box;

    border-radius: 10px;
    margin-top: 15px;
    background-color: #f0f0f0;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    #meb-input {
      height: 30px;
      background-color: transparent;
      border: none;
      width: 60%;
    }
    button {
      width: 66px;
      border: none;
      height: 30px;
      background: #000;
      color: #fff;
      text-align: center;
      opacity: 1;
      border-radius: 10px;
    }
  }
  .name-input {
    margin-top: 20px;
    input {
      width: 100%;
      margin-top: 15px;
      box-sizing: border-box;
      height: 50px;
      border-radius: 10px;
      border: 1px solid #c1c1c1;
      padding-left: 20px;
    }
  }
  .des {
    margin-top: 20px;
    color: #818086;
    line-height: 1.5em;
  }
  .cofirm-button {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: bold;
    height: 50px;
    line-height: 50px;
    background: #000;
    color: #fff;
    text-align: center;
    border-radius: 10px;
  }
}
</style>
