<template>
  <div class="wrap">
    <div class="dao-statistics">
      <svg class="bnb-icon" aria-hidden="true">
        <use xlink:href="#icon-a-zu206"></use>
      </svg>
      <div class="content">
        <div class="title">MEB DAO治理</div>
        <div class="des">
          DAO 是MEB去中心化的重要一步，快来为MEB未來生态发展建言献策
        </div>
        <div class="candidate">候选董事</div>
        <div class="condidate-number">15</div>
        <div class="condidate-button" @click="handleShowDirector">
          成为候选理事
        </div>
        <div class="total">
          <div class="num-title">质押凭证总数:</div>
          <div class="value">3,123,412,233</div>
        </div>
        <div class="total">
          <div class="num-title">总参与人数:</div>
          <div class="value">324,123</div>
        </div>
      </div>
    </div>
    <DirectorList></DirectorList>
    <DirectotPop v-if="directShow" @close="handleShowDirector"></DirectotPop>
  </div>
</template>

<script>
import DirectorList from './components/DirectorList.vue'
import DirectotPop from './components/DirectotPop.vue'

export default {
  components: { DirectorList, DirectotPop },
  name: 'Campaign',
  data () {
    return {
      directShow: false
    }
  },
  methods: {
    handleShowDirector () {
      this.directShow = !this.directShow
    }
  },
  mounted () {
  }
}
</script>

<style scoped lang="scss">
.wrap {
  padding: 20px;
  .dao-statistics {
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    .bnb-icon {
      width: 110px;
      height: 30px;
    }
    .content {
      text-align: center;
      .title {
        padding-top: 15px;
        font-size: 24px;
        font-weight: bold;
        color: #000000;
      }
      .des {
        font-size: 12px;
        padding: 30px 0;
        color: #818086;
        border-bottom: 1px solid #e3e3e3;
      }
      .candidate {
        padding: 15px 0;
        font-size: 16px;
        font-weight: bold;
      }
      .condidate-number {
        font-size: 36px;
        padding: 15px 0;
      }
      .condidate-button {
        font-size: 15px;
        margin: 15px 0;
        color: #fff;
        font-weight: bold;
        line-height: 40px;
        height: 40px;
        background: #000;
        border-radius: 10px;
      }
      .total {
        padding-top: 8px;
        display: flex;
        font-size: 12px;
        align-items: center;
        justify-content: space-between;
        .num-title {
          color: #2a2b33;
        }
        .value {
          color: #2a2b33;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
